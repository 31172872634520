let aiProjectMap = {
  "ai": {
    "leadgen": {
      "meta": {
        "ad": {
          "adName": "Ad Name",
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "conversions": "Leads",
          "cpc": "CPL",
        },
        "adset": {
          "adsetName": "Adset Name",
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "conversions": "Leads",
          "cpc": "CPL",
        },
        "campaign": {
          "campaignName": "Campaign Name",
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "conversions": "Leads",
          "cpc": "CPL",
        }
      },
      "googleAds": {
        "campaign": {
          "campaignName": "Campaign Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cpc": "CPL",
        },
        "ad": {
          "adGroupAdName": "Ad Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cpc": "CPL",
        },
        "adgroup": {
          "adGroupName": "AdGroup Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "conversions": "Leads",
          "cpc": "CPL",
        }
      },
      "dv360": {
        'campaign': {
          "campaignName": "Campaign Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'insertionOrder': {
          "insertionOrderName": "Insertion Order Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'lineItem': {
          "lineItemName": "lineItem Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
      },
    },
    "ecommerce": {
      "meta": {
        "ad": {
          "adName": "Ad Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        },
        "adset": {
          "adsetName": "Adset Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        },
        "campaign": {
          "campaignName": "Campaign Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        }
      },

      "googleAds": {
        "ad": {
          "adGroupAdName": "Ad Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        },
        "adGroup": {
          "adGroupName": "AdGroup Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        },
        "campaign": {
          "campaignName": "Campaign Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "webPurchases": "Purchases",
          "roas": "ROAS"
        },
      },
      "dv360": {
        'campaign': {
          "campaignName": "Campaign Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'insertionOrder': {
          "insertionOrderName": "Insertion Order Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'lineItem': {
          "lineItemName": "lineItem Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
      },
    },
    "default": {
      "meta": {
        "ad": {
          "campaignName": "Ad Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "ROAS": "ROAS"
        },
        "adset": {
          "adsetName": "Adset Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "ROAS": "ROAS"
        },
        "campaign": {
          "campaignName": "Campaign Name",
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "cvr": "CVR",
          "webPurchases": "Purchases",
          "ROAS": "ROAS"
        }
      },
      "googleAds": {
        "ad": {
          "adGroupAdName": "Ad Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          // "webPurchases": "Purchases",
          "ROAS": "ROAS"
        },
        "adGroup": {
          "adGroupName": "AdGroup Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          // "webPurchases": "Purchases",
          "ROAS": "ROAS"
        },
        "campaign": {
          "campaignName": "Campaign Name",
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          // "webPurchases": "Purchases",
          "ROAS": "ROAS"
        },
      },
      "dv360": {
        'campaign': {
          "campaignName": "Campaign Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'insertionOrder': {
          "insertionOrderName": "Insertion Order Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
        'lineItem': {
          "lineItemName": "lineItem Name",
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR %",
          "frequency": "Frequency",

        },
      },
    },
  },
}

export default aiProjectMap